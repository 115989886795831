<template>
  <div class="bjCompanies">
    <Head @closeMenu="closeOpenMenu" />
    <Menu ref="menu" />
    <div class="banner">
      <!-- <div class="forward"></div> -->
    </div>
    <div class="contents">
      <div class="contentCenter">
        <h3 class="title">北京有为信通科技发展有限公司</h3>
        <p class="lines">
          <span class="lineCenter"></span>
        </p>

        <div class="contextCenter">
          <div class="left"></div>
          <div class="right">
            <h2 class="titles">ABOUT</h2>
            <p class="textContent">
              集团旗下北京有为信通科技发展有限公司专注于数字基础设施建设领域，在全国五省布局，业务辐射华北、西北、华南，连续两年登上北京瞪羚企业榜单，被认定为北京科技中小型企业，同时是国家高新技术企业和中关村高新技术企业，取得了ISO9001、14001、18000、27001、45000的认证、信息系统集成认证、安防工程认证、音视频一级认证，在软件领域取得了CMMI3级软件成熟度认证，同时具备多项专利和软件著作权。
            </p>
           
              <a href="http://www.uweic.com/" target="_blank">
               <div class="EnName"></div></a>
            
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <Topreturn @closeMenu="closeOpenMenu"></Topreturn>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Footer from "@/components/footer.vue";
import Menu from "@/components/menu.vue";
import Topreturn from "@/components/Topreturn.vue";
export default {
  components: {
    Head,
    Menu,
    Footer,
    Topreturn
  },
  methods: {
    closeOpenMenu(t) {
      console.log(t);
      this.$refs.menu.menuShow = t;
    },
  },
};
</script>

<style scoped>
/* banner图开始 */
.banner {
  width: 100%;
  height: 1080px;
  background-image: url("../../assets/image/ItsCompanies/banner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.banner .forward {
  width: 164px;
  height: 44px;
  background-image: url("../../assets/image/aboutGroup/index/BnnerContext.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: 58px;
  bottom: 26px;
}
/* banner图结束 */

/* 主体内容开始 */
.contents {
  width: 100%;
  height: 800px;
  background-image: url("../../assets/image/ItsCompanies/bjBground.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.contents .contentCenter {
  padding-top: 78px;
  color: #fff;
  max-width: 1466px;
  margin: 0 auto;
}
.contents .contentCenter .title {
  font-size: 40px;
  font-family: IOS9W4;
  font-weight: bold;
  color: #17171c;
}
.contents .contentCenter .lines {
  margin-bottom: 36px;
}
.contents .contentCenter .lines .lineCenter {
  width: 70px;
  height: 4px;
  background: #3c4047;
  border-radius: 2px;
  display: inline-block;
}
.contents .contentCenter .contextCenter {
  color: #333333;
  display: flex;
  padding-top: 37px;
  justify-content: space-between;
}
.contents .contentCenter .contextCenter .left {
  width: 661px;
  text-align: left;
  display: inline-block;
  background-image: url("../../assets/image/ItsCompanies/bjContent.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-family: Microsoft YaHei;
  overflow: hidden;
  height: 456px;
}
.contents .contentCenter .contextCenter .right {
  width: 850px;
  display: inline-block;
  text-align: left;
  padding-left: 36px;
}
.contents .contentCenter .contextCenter .right .titles {
  font-size: 50px;
  font-family: IOS9W4;
  font-weight: 400;
  color: #999999;
}
.contents .contentCenter .contextCenter .right .textContent {
  padding-top: 27px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 44px;
  width: 762px;
  height: 223px;
}
.contents .contentCenter .contextCenter .right .EnName {
  width: 766px;
  height: 58px;
  background-image: url("../../assets/image/ItsCompanies/bjEnName.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin: 0 auto; */
  margin-top: 90px;
  cursor: pointer;
}

/* hover 事件 */
.contents .contentCenter .contextCenter .left:hover {
  background-size: 105% 105%;
  transition: 0.5s;
}

html {
  font-size: 625%; /*100 ÷ 16 × 100% = 625%*/
}
@media screen and (min-width: 360px) and (max-width: 374px) and (orientation: portrait) {
  html {
    font-size: 703%;
  }
}
@media screen and (min-width: 375px) and (max-width: 383px) and (orientation: portrait) {
  html {
    font-size: 732.4%;
  }
}
@media screen and (min-width: 384px) and (max-width: 399px) and (orientation: portrait) {
  html {
    font-size: 750%;
  }
}
@media screen and (min-width: 400px) and (max-width: 413px) and (orientation: portrait) {
  html {
    font-size: 781.25%;
  }
}
@media screen and (min-width: 414px) and (max-width: 431px) and (orientation: portrait) {
  html {
    font-size: 808.6%;
  }
}
@media screen and (min-width: 432px) and (max-width: 479px) and (orientation: portrait) {
  html {
    font-size: 843.75%;
  }
}
</style>